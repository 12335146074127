import { AxiosResponse } from 'axios';

import {
  Client,
  ClientInfo,
  Clients,
  CommonPersonalData,
  CompanyClient,
  IndividualClient,
  KrsCompanyPersonalData,
  MarketingConsentsPayload,
} from 'types/client';
import { ClientType } from 'types/client';
import http from 'utils/axios';

export const getClient = async (crmClientId: string, clientType: ClientType, agentId: number) => {
  const { data } = await http.get<Client>(
    `/agents/${agentId}/clients/${crmClientId}?clientType=${clientType}`
  );

  return data;
};

export const saveClientMarketingConsents = ({ data, agentId }: MarketingConsentsPayload) =>
  http.post(`/agents/${agentId}/clients`, data);

export const getClients = async (query: string, agentId: number) => {
  const { data } = await http.get<Clients>(`/agents/${agentId}/clients?queryParam=${query}`);

  return data;
};

export type ClientVerificationData = {
  clientData: Omit<IndividualClient | CompanyClient, 'clientType'>;
  aclientId: number;
  verificationCode?: string;
};

export const updateClient = async (
  agentId: number,
  assessmentId: number,
  payload: ClientVerificationData
) => http.post(`/agents/${agentId}/assessments/${assessmentId}/clients`, payload);

export const getClientByCrmClientIdOrCreate = async (crmClientId: string, agentId: number) => {
  const { data } = await http.post<ClientInfo>(`/agents/${agentId}/clients/${crmClientId}/base`);

  return data;
};

export const createClient = async (agentId: number) => {
  const { data } = await http.post(`/agents/${agentId}/clients/create`, {
    clientType: 'INDIVIDUAL',
  });

  return data;
};

export const verifyNip = async (nip: string): Promise<KrsCompanyPersonalData> => {
  const { data } = await http.get<KrsCompanyPersonalData>(`/verifyNip/${nip}`);

  return data;
};

export const getClientData = async (
  agentId: number,
  assessmentId: number,
  aClientId: number,
  clientType: ClientType
) => {
  const { data } = await http.get<IndividualClient | CompanyClient>(
    `/agents/${agentId}/assessments/${assessmentId}/clients`,
    {
      params: {
        aClientId,
        clientType,
        withStatements: clientType === 'COMPANY' ? false : true,
      },
    }
  );

  return data;
};
