import { Box } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { ClientVerificationData, updateClient } from 'services/client';
import { ASSESSMENT_TYPE, StateType } from 'types/assessment';
import { CompanyClient } from 'types/client';

import FormContent from './FormContent';
import { personalData, validationSchema } from './validation';

export type FormValues = Omit<CompanyClient, 'clientType'>;

type Props = {
  aclientId: number;
  clientId: number;
  client: CompanyClient;
};

const CompanyClientForm = ({ clientId, aclientId, client }: Props) => {
  const agentId = useAgentStore((state) => state.agent.agentId);
  const { validateSteps } = useStepValidation();

  const { assessmentId, assessmentState } = useAssessmentStore((state) => ({
    assessmentId: state.assessment.assessmentId || NaN,
    assessmentState: state.assessment.state,
  }));

  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, ClientVerificationData>(
    (payload) => updateClient(agentId, assessmentId, payload),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas zapisywania danych',
          type: 'error',
        });
      },
      onSuccess: () => {
        addNotification({
          id: 'postArea-autosave',
          title: 'Zapisywanie danych',
          type: 'info',
        });
        removeNotification('postArea-error');
        validateSteps(ASSESSMENT_TYPE.YOUR_DATA);
      },
    }
  );

  const initialValues: FormValues = useMemo(
    () => ({
      personalData: {
        ...personalData.cast({}),
        ...{
          ...client.personalData,
          phone: (client.personalData.phone.length === 9 ? '48' : '') + client.personalData.phone,
        },
      },
    }),
    [client]
  );

  const handleSubmit = (values: FormValues) => {
    const { personalData } = values;

    mutate({
      aclientId,
      clientData: {
        personalData: {
          ...personalData,
          phone: personalData.phone?.startsWith('48')
            ? personalData.phone.slice(2, personalData.phone.length)
            : personalData.phone,
          id: clientId,
        },
        statements: [],
      },
    });
  };
  const isReadOnly = assessmentState === StateType.FINISHED || !!client?.crmClientId;

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(client.personalData.isSpBusiness)}>
        <FormContent handleSubmit={handleSubmit} isReadOnly={isReadOnly} />
      </Formik>
    </Box>
  );
};

export default CompanyClientForm;
