import { Button, InputField } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';

import FieldRow from 'components/FieldRow';
import useNotificationStore from 'hooks/useNotificationStore';
import { verifyNip } from 'services/client';
import { KrsCompanyPersonalData } from 'types/client';

import PhonePrefixInputField from '../../../../components/PhonePrefixInputField/PhonePrefixInputField';
import AutoSave from '../AutoSave';
import { FormValues } from './CompanyClientForm';

interface Props {
  handleSubmit: (values: FormValues) => void;
  isReadOnly: boolean;
}

function FormContent({ handleSubmit, isReadOnly }: Props) {
  const { errors, values, setValues } = useFormikContext<FormValues>();
  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const { mutate: mutateNip } = useMutation<KrsCompanyPersonalData, AxiosError, string>(
    (nip) => verifyNip(nip),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas pobierania danych firmy',
          type: 'error',
        });
      },
      onSuccess: (data: KrsCompanyPersonalData) => {
        setValues({
          personalData: {
            ...values.personalData,
            ...data,
          },
        });

        addNotification({
          id: 'postArea-autosave',
          title: 'Dane firmy zostały pobrane',
          type: 'success',
        });
        removeNotification('postArea-error');
      },
    }
  );

  function verifyCompanyNip(nip?: string) {
    if (!nip) return;
    mutateNip(nip);
  }

  return (
    <div className="flex w-full">
      <div className="w-2/3">
        <Form className="space-y-8">
          {!isReadOnly && <AutoSave handleSubmit={handleSubmit} />}
          <FieldRow description="Dane firmy:">
            <div className="space-y-4 w-full">
              <InputField name="personalData.nip" label="Nip firmy" isDisabled={isReadOnly} />
              <InputField name="personalData.name" label="Nazwa firmy" isDisabled={isReadOnly} />
              <InputField name="personalData.regon" label="Regon firmy" isDisabled={isReadOnly} />
            </div>
          </FieldRow>

          <FieldRow description="Osoba kontaktowa:">
            <div className="space-y-4">
              <InputField name="personalData.firstName" label="Imię" isDisabled={isReadOnly} />
              <InputField name="personalData.lastName" label="Nazwisko" isDisabled={isReadOnly} />
              {typeof values.personalData.phone === 'string' && (
                <PhonePrefixInputField name="personalData.phone" label="Telefon" />
              )}
              <InputField name="personalData.email" label="E-mail" />
            </div>
          </FieldRow>

          <FieldRow description="Adres działalności (opcjonalny):">
            <div className="space-y-4">
              <InputField name="personalData.street" label="Ulica" isDisabled={isReadOnly} />
              <InputField name="personalData.houseNo" label="Numer domu" isDisabled={isReadOnly} />
              <InputField
                name="personalData.flatNo"
                label="Numer mieszkania"
                isDisabled={isReadOnly}
              />
              <InputField
                name="personalData.zipCode"
                label="Kod pocztowy"
                isDisabled={isReadOnly}
              />
              <InputField name="personalData.city" label="Miejscowość" isDisabled={isReadOnly} />
            </div>
          </FieldRow>
        </Form>
      </div>
      <div className="my-7 pl-2">
        <Button
          className="!pt-3 !pb-3 !whitespace-nowrap"
          variant="outline-primary"
          isDisabled={!!errors?.personalData?.nip || isReadOnly || !values.personalData.nip}
          onPress={() => verifyCompanyNip(values.personalData.nip)}>
          Weryfikuj NIP
        </Button>
      </div>
    </div>
  );
}

export default FormContent;
